import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import LogRocket from 'logrocket';
import NewRelic from '~/components/util/NewRelic';

// Redux
import { store } from '~/redux/store';
import { Provider } from 'react-redux';

// Components
import NavHeader from '~/components/rebrand/Navheader';
import Footer from '~/components/rebrand/Footer';

// Providers
import { LegalProvider } from '~/providers/LegalProvider';
import { CallCenterProvider } from '~/providers/CallCenterProvider';
import { FuseProvider } from '~/providers/FuseProvider';

// Helpers
import { setMCID, getUrlParameter, sanitizeNetworkData } from '~/helpers/utils';
import Script from 'next/script';
import { getFilteredComponentName } from '~/helpers/utils/getFilteredComponentName';

import listOfPreampAssets from 'preamp/listOfPreampAssets';
// Global CSS - Foundation Grid
import '@red-digital/react-devkit/scss/foundation/foundation.scss';
import {
  CohesionProvider,
  MonarchPreampPlacement,
} from '@redventures/cohesion-utils-react';
import { CohesionScript } from '@red-digital/bricks';
LogRocket.init('0jc5un/hughesnet-cart', {
  release: process.env.release,
  network: {
    requestSanitizer: sanitizeNetworkData(['suggestions']),
    responseSanitizer: sanitizeNetworkData(['suggestions']),
  },
});
function MyApp({ Component, pageProps }) {
  const { events } = useRouter();
  const [updateFuse, setUpdateFuse] = useState(false);
  const [logRocketIdentify, setLogRocketIdentify] = useState(false);

  useEffect(() => {
    // Set MarketingCodeId
    setMCID();

    // Fire on route change
    events.on('routeChangeComplete', () => {
      // Tagular Page View
      window.tagular && window.tagular('pageView');

      // New Relic Page View
      if (window && window.newrelic) {
        newrelic.interaction().save();
      }
    });
  }, []);

  useEffect(() => {
    if (!logRocketIdentify) {
      setLogRocketIdentify(true);
      window?.cohesion('ready', () => {
        LogRocket.identify(window._Cohesion.sessionId);
      });
    }
  }, [logRocketIdentify]);

  useEffect(() => {
    // Set fuse metadata from url params
    if (!updateFuse) {
      setUpdateFuse(true);
      window?.cohesion('fuse:done', () => {
        if (!getUrlParameter('campaignID') && !getUrlParameter('tfn')) {
          return;
        }
        const campaignId = getUrlParameter('campaignID') || '';
        const tfn = getUrlParameter('tfn') || '';
        fuse('meta', { campaignId, tfn });
      });
    }
  }, [updateFuse]);

  return (
    <>
      <NewRelic />
      {process.env.ENVIRONMENT === 'production' && <Script src='/js/gtm.js' />}

      <Provider store={store}>
        <LegalProvider>
          <FuseProvider>
            <CallCenterProvider>
              <CohesionProvider components={listOfPreampAssets}>
                {/* Home | Header */}
                {/* Without this div, the header will no longer be sticky */}
                <div style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                  <MonarchPreampPlacement
                    placement='11Dkf8jk8NTB736rAZhnP2'
                    componentId={getFilteredComponentName}
                  >
                    <NavHeader />
                  </MonarchPreampPlacement>
                </div>
                <main>
                  <Component {...pageProps} />
                </main>

                {/* Home | Footer */}
                <MonarchPreampPlacement
                  placement='7ndJV7yVZO8e5UyajOqUMF'
                  componentId={getFilteredComponentName}
                >
                  <Footer />
                </MonarchPreampPlacement>
              </CohesionProvider>
            </CallCenterProvider>
          </FuseProvider>
        </LegalProvider>
      </Provider>
    </>
  );
}
// Runs whenever the app initializes
export const reportWebVitals = (metric) => {
  if (process.env.ENVIRONMENT !== 'production') {
    return;
  }

  if (metric.label === 'web-vital') {
    import('~/helpers/utils/cwv.js').then((module) => module.default(metric));
  }
};

MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
};

export default MyApp;
