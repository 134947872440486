import React, { useState, useEffect, useCallback } from 'react';
import styles from './AddressForm.module.scss';
import Button from '~/components/shared/Button';
import { SMARTY_STREETS } from '~/constants/smarty-streets';
import SuggestionItems from '~/components/shared/AddressForm/SuggestionList';
import Typography from '~/components/shared/Typography';
import { addPageAction } from '~/helpers/utils';
import { MapPin } from '~/components/icons/MapPin';
import { useMedia } from '~/helpers/hooks';

export default function AddressForm({
  componentLocation,
  metadata = {},
  isHero = false,
  isNav = false,
  isModal = false,
}) {
  const {
    mapPinIconColor,
    addressPlaceHolderCopy,
    addressCTACopy,
    addressLabelCopy,
    addressLabelColor,
    mobileAddressCTACopy,
    bottomNav,
  } = metadata;

  const isMedia = useMedia();
  const [zipCode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const location = componentLocation ? componentLocation : 'Homepage';
  const position = isHero
    ? 'Hero'
    : isNav
    ? 'Nav'
    : isModal
    ? 'Modal'
    : 'FinalEpq';

  const { API_KEY, AUTOCOMPLETE_URL } = SMARTY_STREETS;

  const checkFormButton = useCallback(() => {
    if (address && zipCode) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  });

  useEffect(() => {
    checkFormButton();
  }, [zipCode, address, checkFormButton]);

  const setCookie = (name, value, exmins) => {
    const d = new Date();
    d.setTime(d.getTime() + exmins * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  };

  const onFormSubmit = async (event) => {
    event.preventDefault();
    if (address !== '') {
      fetch(`${AUTOCOMPLETE_URL}?auth-id=${API_KEY}&search=${address}`)
        .then((res) => res.json())
        .then((body) => {
          const data = body.suggestions[0];
          const userData = {
            street_line: data.street_line,
            city: data.city,
            state: data.state,
            zip: data.zipcode,
            zip4: '',
            isUnit: false,
            primary: true,
            unitType: false,
            unitValue: false,
            zip5: data.zipcode,
            zip9: '',
            zip9or5: data.zipcode,
          };
          setCookie('address', JSON.stringify(userData), 30);

          // Tagular track form submitted
          window.tagular('beam', 'FormSubmitted', {
            '@type': 'redventures.usertracking.v3.FormSubmitted',
            formContext: {
              formType: 'Cart CTA',
              formName: `${position}`,
              formId: 'Homepage',
            },
          });

          window.location.href = '/order-online/product-selection/';
        })
        .catch((error) => {
          addPageAction('addressFormSubmissionFailed', { error });
        });
    } else {
      //Navigate to order online page
      window.location.href = '/order-online/';
    }
  };

  const fetchSuggestions = (query) => {
    fetch(`${AUTOCOMPLETE_URL}${query}`)
      .then((res) => res.json())
      .then((body) => {
        const newSuggestions = body.suggestions.filter(
          (suggestion) => suggestion.state !== 'PR'
        );
        setSuggestions(newSuggestions);
      })
      .catch((error) => {
        addPageAction('addressFormFetchSuggestionsFailed', { error });
      });
  };

  const onAddressChange = async (value) => {
    setAddress(value);
    if (!value) {
      setDisableButton(false);
      setAddress('');
      setZipCode('');
      setSuggestions([]);
      setShowSuggestions(false);
      setActiveIdx(0);
    } else {
      const searchQuery = `?auth-id=${API_KEY}&search=${value}&prefer_geolocation=city`;

      fetchSuggestions(searchQuery);
      if (suggestions) {
        const uniqueSet = new Set();
        const uniqueAddress = [];

        suggestions.map((item) => {
          if (!uniqueSet.has(item.street_line)) {
            uniqueSet.add(item.street_line);
            uniqueAddress.push(item);
          }
          return null;
        });
        setSuggestions(uniqueAddress);
        setShowSuggestions(true);
        setActiveIdx(0);
      } else {
        setShowSuggestions(false);
      }
    }
  };

  const onSuggestionClick = async (item) => {
    const selected = `${item.street_line} ${item.secondary} (${item.entries}) ${item.city} ${item.state} ${item.zipcode}`;
    if (item.secondary && item.entries > 1) {
      const searchQuery = `?auth-id=${API_KEY}&search=${item.street_line}&selected=${selected}`;

      const suggestions = await fetchSuggestions(searchQuery);
      if (suggestions) {
        setSuggestions(suggestions);
      }
    } else {
      if (item.secondary) {
        setAddress(
          `${item.street_line}, ${item.secondary} - ${item.city}, ${item.state} ${item.zipcode}`
        );
      } else {
        setAddress(
          `${item.street_line} - ${item.city}, ${item.state} ${item.zipcode}`
        );
      }
      setZipCode(item.zipcode);
      setShowSuggestions(false);

      // Tagular track suggestion clicked
      window.tagular('beam', 'ElementClicked', {
        '@type': 'redventures.usertracking.v3.ElementClicked',
        webElement: {
          location: location,
          position: 'ADDRESS AUTOFILL',
          htmlId: '',
          elementType: 'DROP DOWN',
          text: 'Used Suggestion',
        },
        actionOutcome: 'Internallink',
      });

      checkFormButton();
    }
  };

  const handleAddressKeyDown = (event) => {
    if (event.keyCode === 27) {
      // handle escape key
      setShowSuggestions(false);
    } else if (event.keyCode === 40) {
      // handle down arrow
      if (activeIdx >= suggestions.length) {
        setActiveIdx(0);
      } else {
        setActiveIdx(activeIdx + 1);
      }
    } else if (event.keyCode === 38) {
      // handle up arrow
      if (activeIdx > 0) {
        setActiveIdx(activeIdx - 1);
      }
    } else if (event.keyCode === 13) {
      // handle enter
      event.preventDefault();
      const address = suggestions[activeIdx];
      onSuggestionClick(address);
    }

    checkFormButton();
  };

  const onInputClick = () => {
    // handle address input field on click (means form started)
    window.tagular('beam', 'FormStarted', {
      '@type': 'redventures.usertracking.v3.FormStarted',
      formContext: {
        formType: 'ADDRESS CHECK FORM',
        formName: `ADDRESS INPUT FIELD ${location}`,
        formId: '',
      },
    });
  };

  const onSuggestionKeyDown = (event, suggestion) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSuggestionClick(suggestion);
    }
  };

  const onCloseClick = () => {
    // Tagular track 'close suggestions' button clicked
    window.tagular('beam', 'ElementClicked', {
      '@type': 'redventures.usertracking.v3.ElementClicked',
      webElement: {
        location: location,
        position: 'ADDRESS AUTOFILL',
        htmlId: '',
        elementType: 'DROP DOWN',
        text: 'Closed suggestions',
      },
      actionOutcome: '',
    });

    setShowSuggestions(false);
  };

  return (
    <form
      onSubmit={onFormSubmit}
      autoComplete='off'
      className={styles.addressForm}
    >
      <div
        className={
          isHero
            ? ''
            : isNav
            ? styles.isNavContainer
            : isModal
            ? styles.isModalContainer
            : styles.nonHeroContainer
        }
      >
        <Typography
          variant='h2smaller'
          className={`${styles.addressHeadline} ${
            !isNav && styles.heroAddressHeadline
          }`}
        >
          <span style={{ color: addressLabelColor }}>
            {addressLabelCopy || 'Find special offers in your area'}
          </span>
        </Typography>
        <fieldset
          className={`${styles.fieldset} ${
            isNav
              ? styles.isNavFieldSet
              : !isHero && !isNav
              ? styles.nonHeroFieldset
              : ''
          }`}
        >
          <div
            className={`${styles.inputWrapper} ${
              isNav
                ? styles.isNavInputWrapper
                : !isHero && !isNav
                ? styles.nonHeroInputWrapper
                : ''
            }`}
          >
            <div className={styles.mapPin}>
              <MapPin color={mapPinIconColor} />
            </div>
            <input
              value={address}
              onChange={(e) => onAddressChange(e.target.value)}
              name='address'
              className={`${styles.textInput} ${styles.textInputColor}`}
              onKeyDown={(e) => handleAddressKeyDown(e)}
              onClick={onInputClick}
              placeholder={addressPlaceHolderCopy}
            />
            {showSuggestions && (
              <div
                className={`${styles.suggestionsList} ${
                  bottomNav && isMedia.mobile && styles.suggestionsListBottom
                }`}
              >
                {bottomNav && isMedia.mobile && (
                  <div
                    className={styles.suggestionsListClose}
                    onClick={onCloseClick}
                  >
                    <Typography
                      variant='p1'
                      className={styles.suggestionsListCloseCopy}
                    >
                      Close address suggestions
                    </Typography>
                  </div>
                )}
                <ul
                  className={`${styles.suggestionsListItems} ${
                    bottomNav &&
                    isMedia.mobile &&
                    styles.suggestionsListItemsBottom
                  }`}
                >
                  <SuggestionItems
                    items={suggestions}
                    onSuggestionClick={onSuggestionClick}
                    activeIdx={activeIdx}
                    handleKeyDown={onSuggestionKeyDown}
                    metadata={metadata}
                  />
                </ul>
                {!bottomNav && isMedia.mobile && (
                  <div
                    className={styles.suggestionsListClose}
                    onClick={onCloseClick}
                  >
                    <Typography
                      variant='p1'
                      className={styles.suggestionsListCloseCopy}
                    >
                      Close address suggestions
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </div>
          <Button
            isDisabled={address !== '' && disableButton}
            className={`${styles.btn} ${
              isNav
                ? styles.isNavBtn
                : !isHero && !isNav
                ? styles.nonHeroBtn
                : ''
            }`}
          >
            {isNav && isMedia.mobile ? mobileAddressCTACopy : addressCTACopy}
          </Button>
        </fieldset>
      </div>
    </form>
  );
}
